/*!
Copyright (C) 2022 Liberty Infrasystems LLC. All rights reserved.
*/

// TODO: non-profit and government should be MODIFIERS on an account, not an actual account type; because functionally they would be equivalent to either team or company/enterprise
// customer account type definitions; organizations are not required to support all account types
const ACCOUNT_TYPE_GUEST = 'guest'; // an account used for a one-time purchase with no linked users; cannot be used to buy services because that requires at least one linked administrative user for managing the account
const ACCOUNT_TYPE_INDIVIDUAL = 'individual'; // an account with exactly one (administrative) user
const ACCOUNT_TYPE_TEAM = 'team'; // an account with at least one administrative user; admin may add more admins and non-admins; this account asks for team information instead of company information, and makes 'larger organization' information optional such as if the team is part of a company, non-profit, or government agency
const ACCOUNT_TYPE_ENTERPRISE = 'enterprise'; // an account with at least one administrative user; admin may add more admins and non-admins; this account asks for team information instead of company information, and makes 'larger organization' information optional such as if the team is part of a company, non-profit, or government agency
const ACCOUNT_TYPE_PROFESSIONAL = 'professional'; // an account with exactly one (administrative) user
const ACCOUNT_TYPE_HOUSEHOLD = 'household'; // an account with at least one administrative users; admin may add more admins and non-admins; some users may be marked as minors; minors may need admin permission to make purchases
const ACCOUNT_TYPE_COMPANY = 'company'; // an account with at least one administrative user; admin may add more admins and non-admins; partnerships, LLCs, and corporations should use this account type
const ACCOUNT_TYPE_NON_PROFIT = 'non_profit'; // an account with at least one administrative user; admin may add more admins and non-admins; we may do some verification of the non-profit status (or maybe delegate that to brandprofile) and then offer discounts, or give them a checkbox option for us to display a "verified non-profit" badge on their storefront
const ACCOUNT_TYPE_GOVERNMENT = 'government'; // an account with at least one administrative user; admin may add more admins and non-admins

const ACCOUNT_TYPE_LIST = [
    ACCOUNT_TYPE_GUEST,
    ACCOUNT_TYPE_INDIVIDUAL,
    ACCOUNT_TYPE_TEAM,
    ACCOUNT_TYPE_ENTERPRISE,
    ACCOUNT_TYPE_PROFESSIONAL, // TODO: should be a modifier
    ACCOUNT_TYPE_HOUSEHOLD, // TODO: should be a modifier
    ACCOUNT_TYPE_COMPANY, // TODO: should be a modifier
    ACCOUNT_TYPE_NON_PROFIT, // TODO: should be a modifier
    ACCOUNT_TYPE_GOVERNMENT, // TODO: should be a modifier
];

// user authentication mode setting for each organization
const AUTHENTICATION_MODE_CONNECT = 'connect'; // aka 'external', managed by the organization's own website (for organizations offering saas/membership products on a separate website and handling payments via unicorn springs; could also be for regular products if there's another website handling the user sign up / sign in, catalog, etc. and just using unicorn springs for secure payments and account management) and client application will declare the user and account that are arriving when they use the connect API
const AUTHENTICATION_MODE_DELEGATE = 'delegate'; // aka 'internal', managed by unicorn springs
const AUTHENTICATION_MODE_SSO = 'sso'; // managed by external SSO that both client application website and unicorn springs can share

// interaction types
const IA_LOGIN = 'login';
const IA_SIGNUP = 'signup';
const IA_VERIFY_EMAIL = 'verify_email';
const IA_CUSTOMER_CONNECT = 'customer_connect';
// const IA_PURCHASE = 'purchase';

// intent types generally correspond to interaction types but are conceptually different
const INTENT_LOGIN = 'login'; // sign in to an account
const INTENT_SETUP = 'setup'; // set up a new account
const INTENT_SIGNUP = 'signup'; // subscribe to a service (saas or memebrship, free or paid)
const INTENT_BILLING = 'billing'; // account overview, statements, and make a payment if needed
const INTENT_CART = 'cart'; // see shopping cart, typically with product id already specified so it can be automatically added to the cart
const INTENT_CATALOG = 'catalog'; // browse the organization's catalog (published items only)
const INTENT_CLOSE_ACCOUNT = 'close_account'; // close the account

const INTENT_LIST = [
    INTENT_LOGIN,
    INTENT_SETUP,
    INTENT_SIGNUP,
    INTENT_BILLING,
    INTENT_CART,
    INTENT_CATALOG,
    INTENT_CLOSE_ACCOUNT,
];

// product types
const PRODUCT_TYPE_DONATION = 'donation';
const PRODUCT_TYPE_MEMBERSHIP = 'membership';
const PRODUCT_TYPE_SAAS = 'saas';
const PRODUCT_TYPE_LIST = [
    PRODUCT_TYPE_DONATION,
    PRODUCT_TYPE_MEMBERSHIP,
    PRODUCT_TYPE_SAAS,
];
const PRODUCT_CATEGORY_DONATION = [
    PRODUCT_TYPE_DONATION,
];
const PRODUCT_CATEGORY_SERVICE = [
    PRODUCT_TYPE_MEMBERSHIP,
    PRODUCT_TYPE_SAAS,
];

// account registration mode for each organization
const REGISTRATION_MODE_CONNECT = 'connect'; // aka 'external', managed by the organization's own website; this is orthogonal to authentication: organization might specify that they do authentication, but not require accounts to be created there because it's convenient to do it at checkout
const REGISTRATION_MODE_OPEN = 'open'; // users are allowed to create new accounts here
const REGISTRATION_MODE_RESTRICTED = 'restricted'; // users can only create new accounts if they already have an invitation
const REGISTRATION_MODE_MODERATED = 'moderated'; // users can ask for new accounts, and an organization admin has to approve them before they can be used
const REGISTRATION_MODE_CLOSED = 'closed'; // users cannot create new accounts; this is useful when the organization's service is in alpha testing, or they temporarily need to suspend new registrations to take care of an issue

// service setting names
const SETTING_AUTHENTICATION_MODE = 'authentication_mode'; // whether authentication is handled by unicorn springs ('internal', delegated to loginfront/loginshield) or by the organization's own application ('external')
const SETTING_AUTHENTICATION_EXTERNAL_URL = 'authentication_external_url'; // if authentication is handled externally ('connect'), the url to which we should redirect users when we need them to authenticate
const SETTING_REGISTRATION_MODE = 'registration_mode';
const SETTING_REGISTRATION_EXTERNAL_URL = 'registration_external_url'; // if registration is handled externally ('connect'), the url to which we should redirect users when they want to create a new account
const SETTING_BRANDPROFILE = 'brandprofile';
const SETTING_HOSTNAME = 'hostname';

export {
    ACCOUNT_TYPE_GUEST,
    ACCOUNT_TYPE_INDIVIDUAL,
    ACCOUNT_TYPE_PROFESSIONAL,
    ACCOUNT_TYPE_HOUSEHOLD,
    ACCOUNT_TYPE_TEAM,
    ACCOUNT_TYPE_ENTERPRISE,
    ACCOUNT_TYPE_COMPANY, // TODO: remove this, it should be a modifier on team/company
    ACCOUNT_TYPE_NON_PROFIT, // TODO: remove this, it should be a modifier on team/company
    ACCOUNT_TYPE_GOVERNMENT, // TODO: remove this, it should be a modifier on team/company
    ACCOUNT_TYPE_LIST,
    AUTHENTICATION_MODE_CONNECT,
    AUTHENTICATION_MODE_DELEGATE,
    AUTHENTICATION_MODE_SSO,
    IA_LOGIN,
    IA_SIGNUP,
    IA_VERIFY_EMAIL,
    IA_CUSTOMER_CONNECT,
    INTENT_BILLING,
    INTENT_CART,
    INTENT_CATALOG,
    INTENT_CLOSE_ACCOUNT,
    INTENT_LOGIN,
    INTENT_SETUP,
    INTENT_SIGNUP,
    INTENT_LIST,
    PRODUCT_TYPE_DONATION,
    PRODUCT_TYPE_MEMBERSHIP,
    PRODUCT_TYPE_SAAS,
    PRODUCT_TYPE_LIST,
    PRODUCT_CATEGORY_DONATION,
    PRODUCT_CATEGORY_SERVICE,
    REGISTRATION_MODE_CONNECT,
    REGISTRATION_MODE_OPEN,
    REGISTRATION_MODE_RESTRICTED,
    REGISTRATION_MODE_MODERATED,
    REGISTRATION_MODE_CLOSED,
    SETTING_AUTHENTICATION_MODE,
    SETTING_AUTHENTICATION_EXTERNAL_URL,
    SETTING_REGISTRATION_MODE,
    SETTING_REGISTRATION_EXTERNAL_URL,
    SETTING_BRANDPROFILE,
    SETTING_HOSTNAME,
};
